.main-header-ctn {
  border-radius: 0px;
  background-color: var(--headerbg) !important;
  // padding-left: 5%;
  background-repeat: no-repeat;
  width: 100%;
  max-height: 60px;
  box-shadow: unset;
  align-items: end;
  min-height: 60px;
  justify-content: center;

  .header-actions {
    .header-right-actions {
      display: flex;

      .bal-container {
        font-size: 8px;
    white-space: nowrap;
    line-height: 9px;
      }
    }

    .notifications-img {
      padding: 0;

      &.MuiButtonBase-root .MuiButton-label {
        padding: 0;
        background: transparent !important;
      }
    }

    .theme-toggle {
      height: 40px;
      background: #000000;
      border: 1px solid #CF8D2E;
      display: flex;
      border-radius: 6px;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      margin-right: 12px;

      .theme-text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;

        color: var(--sub-header-btn-text);
      }

      .theme-btn {
        background: linear-gradient(127.35deg, #FFFFFF 17.88%, rgba(255, 255, 255, 0.53) 83.47%);
        border-radius: 100px;
        padding: 0;
        height: 24px;
        width: 24px;
        min-width: unset;
        margin-left: 13px;

        .MuiButton-label {
          padding: 0;
          background-color: none;
        }
      }
    }

    .MuiButtonBase-root .MuiButton-label {
      width: unset;
      padding: 9px 22px;
      text-transform: capitalize;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    .login-btn {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;


      &.MuiButtonBase-root .MuiButton-label {
        // background-color: #fff !important;
        color: var(--text) !important;
        background: var(--header-btn-background) !important;
        border: 1px solid #CF8D2E;
        border-radius: 6px;

      }

      .username-text {
        padding-left: 14px;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-right: 13px;
      }

      .downArrow {
        path {
          fill: #CF8D2E;
        }
      }

      &.p-0 {
        padding: 0;

        &.MuiButtonBase-root .MuiButton-label {
          padding: 0;
          padding-right: 13px;
        }
      }
    }

    .signup-btn {
      &.MuiButtonBase-root .MuiButton-label {
        // background-color: #EC3451 !important;
        color: var(--text) !important;
        background: var(--header-btn-background) !important;
        border: 1px solid #CF8D2E;
        border-radius: 6px;
      }
    }

    .balance-btn.MuiButtonBase-root .MuiButton-label {
      padding: 9px 12px !important;

      path {
        fill: #CE8D2E;
      }
    }
  }

  .logo-ctn {
    position: absolute;
    left: 41px;
    top: 16px;

    .logo {
      width: 85px;

    }
  }

  @media screen and (max-width: 720px) {
    height: 44px;
    min-height: 44px;
    // background: var(--mob-header-background);
    align-items: stretch;
  }

  .left-side {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;

    .logo-ctn {
      height: 40px;
      margin-right: 30px;

      .logo {
        height: 40px;
      }
    }

    .nav-link {
      text-decoration: none;
      text-align: center;
      margin-left: 0.7rem;
      margin-right: 0.7rem !important;
      display: flex;
      align-items: center !important;

      .nav-link-btn {
        border-radius: 0px;
        color: var(--text) !important;
        vertical-align: middle;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        opacity: .4;
      }

      .border-bottom {
        display: none;
      }
    }

    .active {
      position: relative;

      .border-bottom {
        display: block;
        height: 4px;
        background: #CE8D2E;
        position: absolute;
        width: 100%;
        top: 25px;
      }

      .nav-link-btn {
        opacity: 1;
        transition: width 0.1s;
      }
    }
  }

  margin-top: 0% !important;

  .toolbar {
    display: flex;
    justify-content: center;
    padding: 0;
    min-height: 44px;

    .branding-ctn {
      display: flex;
      justify-content: space-between !important;
      cursor: pointer;

      .site-title {
        width: 80px;

        // filter: var(--icon);
        .site-logo {
          width: 55px;
          max-height: 60px;
        }
      }
    }

    .header-actions {
      display: flex;

      .header-right-actions {
        display: flex;
        align-items: center;
        font-family: var(--font-family);
        font-size: 14px;
        color: var(--text) !important;
        cursor: pointer;
        padding: 6px 2px;
        text-transform: capitalize;

        .searchBox {
          position: absolute;
          top: 0%;
          right: 10%;
          transform: translate(-20%, 20%);
          background-color: var(--headerbg) !important;
          height: 30px;
        }

        .searchButton {
          background-color: var(--headerbg) !important;
          float: right;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.4s;
          background-image: url('../../assets/images/common/search1.svg');
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 15px;
        }

        ::placeholder {
          font-size: 12px;
          color: var(--text) !important;
          font-family: var(--font-family);
          opacity: 1;
        }

        .searchInput {
          border: none;
          background: none;
          outline: none;
          float: left;
          padding: 0;
          color: var(--text);
          font-size: 12px;
          transition: 0.8s;
          line-height: 30px;
          width: 0px;
          padding: 3px;
        }

        @media screen and (max-width: 620px) {
          .searchBox:hover>.searchInput {
            width: 150px;
          }
        }

        .MuiSvgIcon-root {
          margin-top: 40%;
        }

        .refresh-icon {
          margin-left: 6px;

          .MuiSvgIcon-root {
            margin-top: 4px !important;
            height: 26px;
            width: 26px;
            transform: rotate(-45deg);
          }

          &.disabled {
            .MuiSvgIcon-root {
              opacity: 0.5;
            }
          }
        }
      }

      .whatsapp-btn {
        @media screen and (max-width: 720px) {
          min-width: fit-content;
          padding: 0;

        }
        .social-icon1 {
          height: 30px;
          margin-top: 4px;
          width: 30px;
          border-radius: 50%;
        }

        .MuiButton-label, .MuiButton-label, .MuiButton-contained {
          background-color: unset !important;
          padding: 0px !important;
      }
      }
    }

    .smedia-icon {
      width: 20px;
      height: 20px !important;
      cursor: pointer;
      margin-right: 20px;
    }

    @media screen and (max-width: 720px) {
      .header-actions {
        width: 100%;
        height: 100%;
        justify-content: space-between;

        .theme-toggle-mobile {
          display: flex;

          .mob-theme-text {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: var(--sub-header-text);
          }

          .mob-theme-toggle {
            background: var(--theme-toggle-btn);
            border: 1px solid #CF8D2E;
            border-radius: 100px;
            min-width: unset;
            padding: 0;
            height: 18px;
            width: 18px;
            margin: 0 12px 0 8px;

            .MuiButton-label {
              padding: 0;
            }
          }
        }

        .header-left-actions {
          display: flex;
          align-items: center;
          padding-left: 12px;
        }

        .notifications-img {
          padding: 0;

          &.MuiButtonBase-root .MuiButton-label {
            padding: 0;
            background: transparent !important;
          }
        }

        .MuiButtonBase-root .MuiButton-label {
          width: unset;
          padding: 6px 18px;
          text-transform: capitalize;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }

        .login-btn {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;


          &.MuiButtonBase-root .MuiButton-label {
            border: 1px solid #CF8D2E;
            border-radius: 6px;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
          }

          .username-text {
            padding-left: 6px;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-right: 6px;
            font-size: 10px;
          }

          .downArrow {
            path {
              fill: #CF8D2E;
            }
          }

          &.p-0 {
            padding: 0;

            &.MuiButtonBase-root .MuiButton-label {
              padding: 0;
              padding-right: 13px;
            }
          }
        }

        .signup-btn {
          padding: 0;
          margin-right: 8px;
          @media screen and (max-width: 720px) {
            &.mob-view {
              min-width: fit-content;

            }
          }

          &.MuiButtonBase-root .MuiButton-label {
            // background-color: #EC3451 !important;
            color: var(--text) !important;
            background: var(--header-btn-background);
            border: 1px solid #CF8D2E;
            border-radius: 6px;
            font-size: 10px;
            padding: 6px 11px;
            line-height: 15px;
          }
        }

        .header-right-actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end !important;
        }
      }
    }


    .box {
      display: flex;
      flex-direction: column;
      margin-left: 18px;
      text-align: center;
      justify-content: center;

      .lable {
        font-size: 12px;
        font-weight: bold;
        color: var(--button) !important;
        text-transform: uppercase;
        margin-top: 2px;
      }

      .dot {
        height: 18px;
        width: 18px;
        background-color: var(--ion-header-background) !important;
        border-radius: 50%;
        border: 2px solid var(--button) !important;
        display: inline-block;
        margin-left: 11px;
        cursor: pointer;
        margin-top: 3px;

        text-align: center;
        justify-content: center;
      }

      .themeicon {
        margin-top: 5px;
        font-size: 25px;
        cursor: pointer;
      }

      .select-drop-down {
        background-color: var(--ion-header-background) !important;
        border: 0px;
        margin-top: 4px;
      }
    }
  }
}

.top-head {
  height: 40px;
  margin: 0;
  padding: 0;
  // background-image: var(--headerbg);
  // background-size: cover;
  // background-repeat: no-repeat;
  background-color: var(--headerbg);
  padding-top: 5px;

  .top-head-contain {
    width: 100%;
    display: flex;

    .canter-contain {
      width: 95%;
      display: flex;
      justify-content: center;

      .social-media-group {
        padding-bottom: 5px;
        padding-top: 5px;

        .smedia-icon {
          width: 20px;
          height: 20px !important;
          cursor: pointer;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }

    .right-contain {
      width: 5%;

      .social-media-group {
        padding-bottom: 5px;
        padding-top: 5px;

        .smedia-icon {
          width: 20px;
          height: 20px !important;
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
  }
}

.user-menu-popover {
  .user-popover {
    --background: var(--ion-popover-background) !important;

    .user-popover-list {
      .nav-link {
        text-decoration: none;
      }

      .ion-list-item {
        --color: var(--text-primary);
        --background: var(--ion-popover-background) !important;
        font-size: 12px;

        .bottom-line {
          border-bottom: 1px solid var(--text-primary);
        }
      }
    }
  }
}

.slide-pane {
  height: 85% !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.sidebar-ctn {
  .header-menu-icon {
    color: var(--text);
    font-size: 22px;
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
  }
}

.some-custom-class {
  .top-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;

    .theme-section {
      display: flex;
      justify-content: center;
      padding-top: 10px;

      .theme-btn {
        background-color: var(--headerbg);
        width: 40px;
        height: 40px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .MuiButton-label {
          background-color: var(--headerbg);
        }
      }
    }

    .nav-link {
      text-decoration: none;
      text-align: center;
      padding: 3px;
      color: var(--text);
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }

  .bottom-section {
    position: absolute;
    bottom: 0px;
    color: var(--text);
    width: 100%;
    border-top: 2px solid var(--active-link);
    padding-top: 10px;

    .user-list {
      background-color: var(--footer-bg) !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .botoom {
        background-color: var(--active-link);
        height: 30px;
        width: 100%;
        margin: 0px;
        color: var(--active-link);
      }

      .label {
        text-decoration: none;
        text-align: center;
        padding: 4px;
        color: var(--text);
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }

  .slide-pane__header {
    flex: 0 0 35px;
    align-items: center;
    background: var(--ion-color-primary);
    height: 35px;
    text-transform: capitalize;
    display: none;

    .slide-pane__close {
      margin-left: 8px !important;
    }

    .slide-pane__title-wrapper {
      // margin-left: 68px;
      font-size: 16px;
    }
  }

  .slide-pane__content {
    padding: 0px !important;
    background-color: var(--footer-bg) !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border-bottom: 2px solid var(--active-link);

    .side-nav-ctn {
      background-color: var(--ion-secondary);
      min-height: 600px;
      border-radius: 15px;

      .profile-ctn {
        padding: 25px;
        display: flex;
        align-items: center;
        height: 150px;

        .profile {
          height: 90px;
          width: 90px;
        }

        .name-ctn {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          color: var(--text);
          margin-left: 10%;

          .small-text {
            font-size: 12px;
            text-align: left;
            font-weight: 400;
          }

          .name-text {
            font-size: 24px;
            text-align: left;
            color: var(--text) !important;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }

      .side-nav-content {
        background-color: var(--headerbg);
        margin-top: 10px;
        border-radius: 0px 100px 15px 15px;
        height: 500px;

        .list {
          padding-top: 30px;

          .list-item {
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            align-items: center;
            height: 80px;
            border-bottom: 0.954217px solid #0d411f;

            .navlink {
              text-decoration: none;
            }

            .lable {
              color: var(--text);
              font-size: 16px;
              font-weight: 400;
              text-decoration: none;
            }

            .icon {
              .icon-img {
                height: 30px;
              }
            }

            .icon1 {
              margin-right: 15px;
            }
          }
        }

        .bottom-section-ctn {
          position: absolute;
          bottom: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          color: var(--text);
          width: 90%;

          a,
          .navlink {
            text-decoration: none;
            color: var(--text) !important;

            .label {
              color: var(--text) !important;
            }
          }
        }
      }
    }
  }
}

.slide-pane__overlay {
  top: 55px !important;

  .some-custom-class .slide-pane__content {
    background-color: var(--footer-bg) !important;
  }
}

.login-modal-ctn {
  display: flex;
  flex-direction: row;
}