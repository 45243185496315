@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './loadingCircle';
@import './search_filter.scss';
@import './scrollbar.scss';
@import './reportpages.scss';
@import './custonStyles.scss';

@font-face {
  font-family: 'Blinker';
  src: url('../fonts/Blinker-Regular.ttf') format('truetype');
}

img {
  image-rendering: -moz-crisp-edges !important;
  image-rendering: -o-crisp-edges !important;
  image-rendering: -webkit-optimize-contrast !important;
  image-rendering: pixelated !important;
  -ms-interpolation-mode: nearest-neighbor !important;
}


/* Backdrop styles */
.MuiBackdrop-root {
  background: rgba(199, 152, 29, 0.2) !important;
}

// global font color
body {
  font-family: 'Blinker';
}

/* Input Styles */
.MuiFormHelperText-contained {
  margin: 0px !important;
  background: none !important;
}

.MuiTableRow-root {
  border-bottom: 1px solid var(--border-color) !important;
}

@supports (-webkit-touch-callout: none) {
  .MuiTableRow-root {
    border-bottom: 1px solid var(--border-color) !important;
  }
}

.MuiTableCell-root {
  border-bottom: 0px !important;
}

.MuiInputBase-root .Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.MuiInputBase-root {
  color: var(--input-text-color) !important;
  font-family: 'Blinker';
  font-size: 14px;
  height: 48px;
  padding: 0;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 8px;

  fieldset {
    border: 0 !important;
  }

  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 16px;
    font-family: 'Blinker';
    padding: 12px 16px;
  }

  .MuiSvgIcon-root {
    color: var(--input-text-color);
  }
}

// input {
//   border: 1px solid var(--ion-background) !important;
// }

.sc-ion-input-md-h input {
  border-radius: 8px !important;
}

.input-label {
  color: var(--ion-text-light);
  font-family: 'Blinker';
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
}

#responsive-dialog-title {
  color: var(--text);
  h2 {
    font-family: 'Montserrat';
  }
}

// Modal styles
// .modal-title {
//   .MuiTypography-root {
//     font-family: var(--headers-font-family) !important;
//     font-size: 32px !important;
//     color: #ffffff;
//   }
// }

// .modal-content-ctn {
//   .date-control,
//   .input-control {
//     border: 1px solid var(--border-color);
//   }
// }

.hand-pt {
  cursor: pointer;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-transition: background-color 9999s ease-out !important;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 0px;
}

.MuiTab-root {
  text-transform: none;
}

.MuiTabs-indicator {
  display: none;
}

/* Punter reports styles */
.admin-module-ctn {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.sticky-col {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 5px;
}

.b-text {
  font-weight: 600;
}

.tiny-info-text {
  font-size: 12px;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.left-align {
  align-items: left !important;
}

.m-link {
  cursor: pointer !important;
}

.profit {
  color: var(--ion-color-success) !important;
}

.loss {
  color: var(--ion-color-danger) !important;
}

// Carousel styles
.carousel .slide {
  background: none !important;
}

.primary-b-text {
  font-family: var(--font-family);
  font-weight: 600;
  // color: var(--ion-color-primary-tint);
}

// .item-native {
//   background: #000;
// }
// .ion-list-item item ios {
//   --background: var(--ion-background) !important;
// }

.sc-ion-popover-ios-h {
  --width: 160px !important;
  --background: var(--ion-background) !important;
}

.sc-ion-popover-md-h {
  --width: 280px !important;
  --padding: 0px;
  --background: var(--ion-background) !important;

  .item {
    --padding: 0px;
    margin: 0px;
    --background: var(--ion-background) !important;
    --color: var(--text) !important;
    // border-bottom: 1px solid var(--text-primary);
  }

  .item .sc-ion-label-md-h {
    --padding: 0px;
    font-size: 12px;
    --color: var(--text) !important;
    font-family: 'Montserrat';
  }
}

.list-md {
  --padding-top: 0px;
  --background: var(--ion-background) !important;
  color: var(--text-primary);
}

@keyframes color-change {
  0% {
    color: red;
  }

  50% {
    color: var(--ion-color-success);
  }

  100% {
    color: var(--ion-color-primary);
  }
}

@keyframes flashing-text {
  0% {
    color: red;
    transform: scale(1.2);
  }

  25% {
    color: var(--ion-color-success);
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  75% {
    color: red;
    transform: scale(1);
  }

  100% {
    color: var(--ion-color-success);
    transform: scale(1.2);
  }
}

@media screen and (max-width: 720px) {
  .web-view {
    display: none !important;
  }
}

@media (min-width: 721px) {
  .mob-view {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .tab-view {
    display: none !important;
  }

  .odds-web-view {
    display: none;
  }
}

@media (min-width: 1200px) {
  .lap-view {
    display: none !important;
  }

  .odds-mob-view {
    display: none;
  }
}

.font-roboto {
  font-family: var(--font-family);
}

.Toastify__toast--success {
  background: var(--ion-toast--success) !important;
  color: var(--text-primary) !important;
}

.empty-cell {
  color: var(--text-primary);
}

.MuiAppBar-colorPrimary {
  background-color: var(--ion-background) !important;
}

:host(.button-block) .button-native {
  background: var(--button) !important;
}

.nav-link {
  text-decoration: none;
  cursor: pointer !important;
}

.t-uppercase {
  text-transform: uppercase;
}

.MuiButton-label,
.MuiButton-label,
.MuiButton-contained {
  background-color: var(--button) !important;
  // color: var(--text-copyright) !important;
}

.sc-ion-popover-ios-h {
  --background: var(--ion-popover-background) !important;
}

.w-100 {
  width: 100%;
}

.popover-content.sc-ion-popover-md {
  // width: 32.4%;
  border: 2px solid #CF8D2E;
  border-radius: 0px 0px 14px 14px;

}

.sc-ion-popover-md-h .item {
  border-bottom: 2px solid #454646 !important;
}

.background-img {
  position: absolute;
  height: 100%;
  opacity: .4;
}

.game-rules-drawer {
  .game-rules-header {
    background-color: #CF8D2E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15.5px 17px;
    font-weight: 600;
  
    .game-rules-title {
      font-weight: 600;
  font-size: 20px;
  line-height: 29px;
    }
    .game-rules-close {
      cursor: pointer;
    }
  }
  .MuiDrawer-paper {
    // background-color: var(--ion-background-color) !important;
    min-width: 450px;
  }

  &.mob-view {
    .MuiDrawer-paper {
      min-width: 100vw;
      .odds-terms-condi-ctn {
        padding: 10px 16px 0 16px;
        .MuiAccordion-root .MuiAccordionSummary-root {
          background: var(--table-header-background) !important;
          .MuiTypography-root {
            font-weight: 600;
font-size: 14px;
line-height: 20px;
          }
        }
      }
    }
  }
}

.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {
  @media screen and (max-width: 920px) {
    color: var(--text);
    font-size: 14px;
    font-family: Blinker;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}